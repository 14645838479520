<template>
   <section class="strenghts">
      <div class="section-name">
         <h2 class="about-me">co mnie wyróżnia<span class="colorDot"></span></h2>
      </div>
      <div class="strenght-items">
         <div class="strength" :class="{ active: ToggleActiveA }" @click="ToggleActive('A')">
            <div class="overlay"></div>
            <img style="height: 80%" src="../assets/images/telescope.svg" alt="teleskop" />
            <h3 class="strength-name">Otwartość na nowe doświadczenia</h3>
            <span class="strength-description"
               >Z entuzjazmem podchodzę do poznawania nowych technologii. Mam świadomość, że praca programisty wymaga ciągłego
               rozwoju i doskonalenia swoich umiejętności.</span
            >
         </div>
         <div class="strength" :class="{ active: ToggleActiveB }" @click="ToggleActive('B')">
            <div class="overlay"></div>
            <img src="../assets/images/game.svg" alt="pad do gry" />
            <h3 class="strength-name">Pasja do programowania</h3>
            <span class="strength-description"
               >Programowanie sprawia mi wiele radości. Proces tworzenia funkcjonalnych rozwiązań od podstaw daje mi ogromną
               satysfakcję i motywację do ciągłego rozwoju.</span
            >
         </div>
         <div class="strength" :class="{ active: ToggleActiveC }" @click="ToggleActive('C')">
            <div class="overlay"></div>
            <img src="../assets/images/bulb.svg" alt="żarówka" />
            <h3 class="strength-name">Kreatywność</h3>
            <span class="strength-description"
               >Kreatywne podejście do rozwiązywania problemów jest kluczową umiejętnością w programowaniu. Staram się zawsze
               szukać niestandardowych i efektywnych rozwiązań, które pozwalają tworzyć lepsze, bardziej innowacyjne
               aplikacje.</span
            >
         </div>
         <div class="strength" :class="{ active: ToggleActiveD }" @click="ToggleActive('D')">
            <div class="overlay"></div>
            <img style="height: 110%; transform: translateX(-25px)" src="../assets/images/team.svg" alt="ludzie" />
            <h3 class="strength-name">Umiejętność pracy w zespole</h3>
            <span class="strength-description"
               >Praca zespołowa jest kluczem do sukcesu. Zwiększa efektywność, skraca czas realizacji projektów, a wspólne
               rozwiązywanie problemów prowadzi do innowacyjnych rozwiązań.</span
            >
         </div>
      </div>
   </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
   name: "StrengthsComp",
   mounted: function () {
      this.scrollAnimation();
   },
   methods: {
      scrollAnimation() {
         const gs = gsap.timeline({
            scrollTrigger: {
               trigger: ".strength",
               start: "120% bottom",
            },
         });
         gs.fromTo(
            ".strenghts",
            { opacity: 0 },
            {
               opacity: 1,
               duration: 1.2,
            }
         );
         return () => {
            // gs.scrollTrigger.kill();
         };
      },
      ToggleActive(proj) {
         if (proj === "A") {
            this.ToggleActiveA = !this.ToggleActiveA;
         } else if (proj === "B") {
            this.ToggleActiveB = !this.ToggleActiveB;
         } else if (proj === "C") {
            this.ToggleActiveC = !this.ToggleActiveC;
         } else if (proj === "D") {
            this.ToggleActiveD = !this.ToggleActiveD;
         }
      },
   },
   data() {
      return {
         ToggleActiveA: false,
         ToggleActiveB: false,
         ToggleActiveC: false,
         ToggleActiveD: false,
      };
   },
};
</script>

<style>
.strenght-items {
   display: flex;
   justify-content: space-evenly;
   flex-wrap: wrap;
   align-items: center;
}

.strength {
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   height: 250px;
   width: 44%;
   border-radius: 10px;
   margin: 25px 0;
   text-transform: uppercase;
   overflow: hidden;
}

.strength::before {
   content: "";
   position: absolute;
   width: 130%;
   height: 100%;
   background-color: rgba(255, 255, 255, 0.08);
   transform: rotateZ(140deg);
}

.strength::after {
   content: "";
   position: absolute;
   top: -100%;
   left: -150%;
   width: 40%;
   height: 1000px;
   background-color: rgba(255, 255, 255, 0.1);
   transform: rotateZ(50deg);
   filter: blur(30px);
}

.strength:hover.strength::after {
   animation: shiny 1.3s linear;
}

.strength h3 {
   position: absolute;
   color: #dadada;
   font-size: 28px;
   text-align: center;
   top: 50%;
   transition: 0.4s linear;
   text-shadow: 0 0 6px rgb(0, 0, 0);
   background-color: black;
   width: 100%;
   padding: 5px 0;
}

.strength-description p {
   text-align: center;
}

.strength img {
   height: 90%;
   transition: 0.4s linear;
}

.strength:hover img {
   filter: blur(10px) contrast(0);
   opacity: 0.3;
}

.strength:hover.strength::after {
   animation: shiny 1.3s linear;
}

.strength:hover .overlay {
   display: none;
}

.strength:hover .strength-description {
   top: 50%;
}

.strength:hover h3 {
   top: -100%;
}

.strength-description {
   position: absolute;
   transform: translateY(-50%);
   text-align: center;
   top: 200%;
   transition: 0.3s ease-in;
   text-transform: none;
   margin: 8px;
}

.overlay {
   position: absolute;
   width: 40px;
   height: 40px;
   background-color: rgba(255, 255, 255, 0.03);
   border-radius: 180px;
   z-index: -1;
}

@media (max-width: 1100px) and (orientation: portrait) {
   h3.strength-name {
      font-size: 26px;
   }

   .strength-description {
      font-size: 18px;
      line-height: 1.4;
      margin: 0 30px;
   }

   .strength:hover img {
      filter: none;
      opacity: 1;
   }

   .strength:hover .overlay {
      display: block;
   }

   .strength:hover .strength-description {
      top: 200%;
   }

   .strength:hover h3 {
      top: 50%;
   }

   .strenght-items {
      flex-direction: column;
      align-items: center;
   }

   .strength.active.strength::after {
      animation: none;
   }

   .strength.active img {
      filter: blur(10px) contrast(0);
      opacity: 0.3;
   }

   .strength.active.strength::after {
      animation: shiny 1.3s linear;
   }

   .strength.active .overlay {
      display: none;
   }

   .strength.active .strength-description {
      top: 50%;
   }

   .strength.active h3 {
      top: -100%;
   }

   .strength {
      width: 90%;
   }
}

@media (max-width: 1200px) and (orientation: landscape) {
   h3.strength-name {
      font-size: 26px;
   }

   .strength-description {
      font-size: 20px;
      margin: 0 30px;
   }

   .strength:hover img {
      filter: none;
      opacity: 1;
   }

   .strength:hover .overlay {
      display: block;
   }

   .strength:hover .strength-description {
      top: 200%;
   }

   .strength:hover h3 {
      top: 50%;
   }

   .strenght-items {
      flex-direction: column;
      align-items: center;
   }

   .strength.active.strength::after {
      animation: none;
   }

   .strength.active img {
      filter: blur(10px) contrast(0);
      opacity: 0.3;
   }

   .strength.active.strength::after {
      animation: shiny 1.3s linear;
   }

   .strength.active .overlay {
      display: none;
   }

   .strength.active .strength-description {
      top: 50%;
   }

   .strength.active h3 {
      top: -100%;
   }

   .strenght-items {
      flex-direction: column;
      align-items: center;
   }

   .strength {
      width: 90%;
   }
}

@media (max-width: 330px) and (orientation: portrait) {
   span.strength-description {
      font-size: 14px;
   }
}
</style>
