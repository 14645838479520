<template>
   <section id="projects" class="projects-section">
      <div class="section-name">
         <h2>projekty<span class="colorDot"></span></h2>
      </div>
      <div class="projects">
         <div id="proj1" class="project" v-bind:class="{ active: ToggleActiveA }">
            <button class="btn shadow-none p-0 mt-2" @click="ToggleActive('A')"><i class="bi bi-arrow-90deg-left"></i></button>
            <h2 class="project-name">Parking Here</h2>
            <div class="project-description">
               <span
                  >Aplikacja oferuje kompleksowe rozwiązanie do zarządzania miejscami parkingowymi. Użytkownicy mogą utworzyć
                  własne konto w systemie, wyszukać parking w wybranej lokalizacji oraz dokonać rezerwacji miejsca parkingowego.
                  Dla właścicieli parkingów platforma umożliwia dodawanie nowych parkingów do systemu, kompleksowe zarządzanie
                  własnymi obiektami oraz monitoring i obsługę rezerwacji.</span
               >
               <div class="project-techs">
                  <span class="project-tech salmon">html5</span><span class="project-tech salmon">css3</span
                  ><span class="project-tech salmon">typescript</span><span class="project-tech salmon">Bootstrap</span
                  ><span class="project-tech salmon">Pinia</span><span class="project-tech salmon">Vue.js</span
                  ><span class="project-tech">mysql</span><span class="project-tech">C#</span
                  ><span class="project-tech">ASP .NET Core</span>
               </div>
               <div>
                  <p class="mt-1">
                     Przejdż na stronę projektu:<a href="https://parkinghere.kiwwwano.com"><i class="bi bi-tv"></i></a>
                  </p>
                  <p>
                     Przejdż na github:<a class="ms-2" href="https://github.com/k3gr/ParkingHereApi"
                        ><span>BE:</span><i class="bi bi-github"></i> </a
                     ><a href="https://github.com/k3gr/ParkingHereWebClient"><span>FE:</span><i class="bi bi-github"></i> </a>
                  </p>
                  <div class="d-lg-flex align-items-baseline mt-2">
                     <p class="me-2">Demo:</p>
                     <p class="me-2">l: user_demo(at)kiwwwano.com</p>
                     <p>h: <span class="text-transform-none">Userdemo$123</span></p>
                  </div>
               </div>
            </div>
            <img @click="ToggleActive('A')" src="../assets/images/parking.png" alt="parking here screenshot" />
         </div>
         <div id="proj2" class="project" v-bind:class="{ active: ToggleActiveB }">
            <button class="btn shadow-none p-0" @click="ToggleActive('B')"><i class="bi bi-arrow-90deg-left"></i></button>
            <h2 class="project-name">Gray Wolf Racing</h2>
            <div class="project-description">
               <span>
                  Był to mój pierwszy projekt aplikacji webowej, któremu poświęciłem wiele czasu i zaangażowania. Głównym celem
                  było stworzenie kompleksowego systemu wspomagającego prowadzenie ligi e-sportowej. Aplikacja została wyposażona
                  w szereg funkcjonalności, takich jak system rejestracji i uwierzytelniania użytkowników oraz możliwość zapisów
                  do bieżących rozgrywek. Wszystkie informacje o zawodnikach i zawodach są przechowywane w bazie danych. Mimo że
                  projekt nie jest już aktywnie wykorzystywany, stanowił on cenne doświadczenie w rozwoju moich umiejętności
                  programistycznych.
               </span>
               <div class="project-techs">
                  <span class="project-tech salmon">html5</span><span class="project-tech salmon">css3</span
                  ><span class="project-tech salmon">javascript</span><span class="project-tech salmon">thymeleaf</span
                  ><span class="project-tech">mysql</span><span class="project-tech">spring</span
                  ><span class="project-tech">docker</span><span class="project-tech">hibernate</span>
               </div>
               <div>
                  <p>
                     Przejdż na github:<a class="ms-2" href="https://github.com/k3gr/Gwrt"
                        ><span>BE:</span><i class="bi bi-github"></i>
                     </a>
                  </p>
               </div>
            </div>
            <img @click="ToggleActive('B')" src="../assets/images/gwr.png" alt="gray wolf racing screenshot" />
         </div>
         <div id="proj3" class="project" :class="{ active: ToggleActiveC }">
            <button class="btn shadow-none p-0" @click="ToggleActive('C')"><i class="bi bi-arrow-90deg-left"></i></button>
            <h2 class="project-name">Portfolio kiwwwano</h2>
            <div class="project-description">
               <span>
                  W trakcie tworzenia portfolio wykorzystałem kilka nowoczesnych technologii. Jedną z nich jest biblioteka
                  GreenSock Animation Platform (GSAP), dzięki której wzbogaciłem stronę o płynne animacje, nadając jej dynamiczny
                  charakter. Dodatkowo, użyłem narzędzia Sigma, które pozwoliło mi stworzyć unikalną animację logo kiwwwano.
               </span>
               <div class="project-techs">
                  <span class="project-tech salmon">html5</span><span class="project-tech salmon">css3</span
                  ><span class="project-tech salmon">javascript</span><span class="project-tech salmon">sigma</span
                  ><span class="project-tech salmon">gsap</span>
               </div>
               <div class="link">
                  <span>Przejdż na stronę projektu:</span><a href="https://kiwwwano.com"><i class="bi bi-tv"></i></a>
               </div>
            </div>
            <img @click="ToggleActive('C')" src="../assets/images/kiwwwano.png" alt="kiwwwano screenshot" />
         </div>
         <div id="proj4" class="project" :class="{ active: ToggleActiveD }">
            <button class="btn shadow-none p-0" @click="ToggleActive('D')"><i class="bi bi-arrow-90deg-left"></i></button>
            <h2 class="project-name">Ticket Market</h2>
            <div class="project-description">
               <span
                  >Pierwszy projekt we Framwework'u Vue. Dzięki API od TicketMaster, strona wyświetla aktualne wydarzenia
                  kulturalne.</span
               >
               <div class="project-techs">
                  <span class="project-tech salmon">html5</span><span class="project-tech salmon">css3</span
                  ><span class="project-tech salmon">javascript</span><span class="project-tech">Vue.js</span>
               </div>
               <div class="link">
                  <span>Przejdż na stronę projektu:</span><a href="https://ticketmarket.kiwwwano.com"><i class="bi bi-tv"></i></a>
               </div>
            </div>
            <img @click="ToggleActive('D')" src="../assets/images/ticketmarket.png" alt="ticketmarket screenshot" />
         </div>
      </div>
   </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
   name: "ProjectsComp",
   mounted: function () {
      this.scrollAnimation();
   },
   methods: {
      scrollAnimation() {
         const gs = gsap.timeline({
            scrollTrigger: {
               trigger: "#proj1",
               start: "50% bottom",
            },
         });
         gs.fromTo(
            ".projects-section",
            { opacity: 0 },
            {
               opacity: 1,
               duration: 1.2,
            }
         );
         return () => {};
      },
      ToggleActive(proj) {
         if (proj === "A") {
            this.ToggleActiveA = !this.ToggleActiveA;
         } else if (proj === "B") {
            this.ToggleActiveB = !this.ToggleActiveB;
         } else if (proj === "C") {
            this.ToggleActiveC = !this.ToggleActiveC;
         } else if (proj === "D") {
            this.ToggleActiveD = !this.ToggleActiveD;
         }
      },
   },
   data() {
      return {
         ToggleActiveA: false,
         ToggleActiveB: false,
         ToggleActiveC: false,
         ToggleActiveD: false,
      };
   },
};
</script>
<style scoped>
.projects {
   margin-bottom: 50px;
}

.project {
   position: relative;
   height: 550px;
   width: 85%;
   left: 0;
   transition: 0.2s linear;
   overflow: hidden;
   border-radius: 10px;
   margin: 30px auto;
}

.project:first-child {
   margin: 0 auto;
}

.project.active.project video,
.project.active.project img {
   filter: saturate(0) blur(4px) brightness(0.6);
   left: 130%;
}

.project:hover.project video,
.project:hover.project img {
   cursor: pointer;
}

.project-description {
   position: relative;
   top: 0;
   left: 0;
   margin: 15px;
   color: #dadada;
   transition: 0.4s linear;
   /* width: 49%; */
   width: 75%;
   height: 100%;
   padding: 0 20px 0 0;
   line-height: 1.3;
}

.project-description span {
   font-size: 22px;
}

.project-tech.salmon {
   background-color: #fd5e5e;
   color: black;
}

.project video,
.project img {
   position: absolute;
   top: 0;
   left: 50%;
   transform: translate(-50%, 0%);
   width: 100%;
   transition: 0.3s linear;
}

.project-name {
   text-transform: uppercase;
   color: #dadada;
   font-size: 60px;
   transition: 0.1s linear;
   position: absolute;
   bottom: 1%;
   padding-right: 20px;
   background-color: black;
   z-index: 1;
}

.project-techs {
   display: flex;
   flex-wrap: wrap;
   margin-top: 15px;
   width: 100%;
}

.project-techs span {
   font-size: 14px;
}

.project-tech {
   margin: 2px 2px 10px 2px;
   height: 26px;
   background-color: #003257;
   padding: 5px 10px;
   border-radius: 10px;
   text-transform: uppercase;
}

.project-tech span {
   font-size: 10px;
}

.link {
   position: absolute;
}

.link a,
a {
   text-decoration: none;
   color: #dadada;
   font-family: "Roboto", "Josefin Sans", sans-serif;
   font-weight: bold;
   text-transform: uppercase;
   width: 40px;
   height: 40px;
}

.link i,
i {
   color: #ffde5f;
   font-size: 26px;
   margin: 10px;
}

.link span,
p {
   font-size: 17px;
   text-transform: uppercase;
   color: #e5e5e5;
   margin-bottom: 0;
}

.link a:hover i,
a:hover i {
   color: #fd5e5e;
}

.bi-github {
   color: rgb(0, 99, 44);
}

.text-transform-none {
   text-transform: none;
   font-size: 17px !important;
}

@media (max-width: 1100px) and (orientation: portrait) {
   .project {
      width: auto;
      height: 500px;
      margin-bottom: 10px;
      overflow: hidden;
   }

   .project img {
      top: -30%;
   }

   .project:hover.project video,
   .project:hover.project img {
      filter: saturate(1) blur(0px) brightness(1);
      left: 50%;
   }

   .project.active.project video,
   .project.active.project img {
      filter: saturate(0) blur(4px) brightness(0.6);
      left: 300%;
   }

   .project-description {
      width: 100%;
      margin: 5px 0;
      padding: 0;
      /* line-height: 1.3; */
   }

   .project-description span,
   .link span,
   p {
      font-size: 14px;
   }

   .project video,
   .project img {
      height: 670px;
      width: auto;
   }

   .project-name {
      padding-right: 10px;
      font-size: 8vw;
      bottom: 0;
      transition: 0.1s linear;
   }

   .project-techs {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      width: 100%;
   }

   .projects-section {
      margin: 0;
   }

   span.project-tech {
      margin: 2px 2px 10px 2px;
      font-size: 12px;
      line-height: 10px;
      height: 20px;
   }
}

@media (max-width: 1200px) and (orientation: landscape) {
   .project {
      width: auto;
      height: 420px;
      margin-bottom: 10px;
      overflow: hidden;
   }

   .project:last-child {
      margin: 0;
   }

   .project-description {
      width: 100%;
      margin: 5px 10px;
   }

   .project-description span {
      font-size: 16px;
      line-height: 1;
   }

   .project video,
   .project img {
      width: 100vw;
   }

   .project:hover.project video,
   .project:hover.project img {
      filter: saturate(1) blur(0px) brightness(1);
      left: 50%;
   }

   .project.active.project video,
   .project.active.project img {
      filter: saturate(0) blur(4px) brightness(0.6);
      left: 220%;
   }

   .project-name {
      padding-right: 0;
      font-size: 8vw;
   }

   .project-techs {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      width: 100%;
   }

   .projects-section {
      margin: 0;
   }

   span.project-tech {
      margin: 2px 2px 10px 2px;
      font-size: 12px;
      line-height: 10px;
      height: 20px;
   }
}

@media (max-width: 330px) and (orientation: portrait) {
   span.strength-description {
      font-size: 14px;
   }

   .project-description {
      padding: 0;
   }

   .project {
      height: 500px;
   }

   .project img {
      width: auto;
      height: 600px;
   }

   .project-description span {
      font-size: 12px;
   }

   .project-techs span {
      font-size: 9px;
      padding: 5px 5px;
   }

   .project.active.project video,
   .project.active.project img {
      filter: saturate(0) blur(0px) brightness(1);
      left: 420%;
   }
}
</style>
