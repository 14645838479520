<template>
   <section id="about" class="about">
      <div class="section-name">
         <h2 class="about-me">Cześć<span class="colorDot"></span></h2>
      </div>
      <div class="text-about-me">
         <img class="code" src="../assets/images/pc.png" alt="laptop" /><span
            >Jestem fullstack developerem z pasją do tworzenia nowoczesnych aplikacji webowych. Specjalizuję się w technologiach
            .NET (C#) oraz Vue.js. Moja ścieżka w programowaniu rozpoczęła się od Javy i Spring Framework, by następnie ewoluować
            w kierunku fullstack developmentu. Posiadam solidne doświadczenie w tworzeniu aplikacji przy użyciu Vue.js z
            TypeScript po stronie frontendowej oraz C# w backendzie. Systematycznie poszerzam swoją wiedzę poprzez kursy i
            samodzielne projekty, co pozwala mi być na bieżąco z najnowszymi trendami w branży. Zapraszam do zapoznania się z moim
            portfolio, które prezentuje wybrane projekty zrealizowane z wykorzystaniem poznanych technologii.</span
         >
      </div>
   </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
   name: "AboutComp",
   mounted: function () {
      this.scrollAnimation();
   },
   methods: {
      scrollAnimation() {
         const gs = gsap.timeline({
            scrollTrigger: {
               trigger: ".about",
               start: "65% bottom",
            },
         });
         gs.fromTo(
            ".about",
            { opacity: 0 },
            {
               opacity: 1,
               duration: 1.2,
            }
         ),
            gs.fromTo(
               ".code",
               { opacity: 0 },
               {
                  opacity: 1,
                  duration: 1.2,
                  delay: -0.8,
               }
            );
         return () => {
            // gs.scrollTrigger.kill();
         };
      },
   },
};
</script>
<style>
.about {
   border-top: none;
}
.code {
   margin-bottom: 10px;
   width: 50%;
   font-size: 10vw;
   float: right;
   shape-outside: polygon(15.53% 2.36%, 83.83% 2.25%, 84.16% 80.2%, 99.54% 107.1%, 49.3% 106.85%, -9.48% 114.04%, 15.35% 74.41%);
}
.text-about-me span {
   font-size: 26px;
}
@media (max-width: 1100px) and (orientation: portrait) {
   .text-about-me span {
      font-size: 18px;
   }

   .code {
      width: 50%;
   }
}

@media (max-width: 1200px) and (orientation: landscape) {
   .text-about-me {
      margin: 0 15px;
   }

   .text-about-me span {
      font-size: 18px;
   }

   .code {
      width: 50%;
   }
}
@media (max-width: 1200px) and (orientation: landscape) {
   .text-about-me {
      margin: 0 15px;
   }

   .text-about-me span {
      font-size: 18px;
   }

   .code {
      width: 50%;
   }
}
</style>
